import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import CreatableAsyncSelect from 'react-select/async-creatable';

function Registration() {
  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState({
    name: '',
    member_id: '',
    nnf_id: '',
    address: '',
    city: '',
    state: '',
    country: '',
    pincode: '',
    email: '',
    phone: '',
    whatsapp: '',
    package: '',
    workshop: false,
    workshopType: '',
    dob: '',
    role: '',
    conference: true,
    registerForCME: false
  });

  const [packages, setPackages] = useState([
    { label: 'Consultant', value: '' },
    { label: 'PG Student', value: 4000 },
    { label: 'Nursing Officer', value: 3000 },
    { label: 'Faculty / Chair Person', value: 4000 },
  ]);

  const [memberOptions, setMemberOptions] = useState([]);
  const [nnfOptions, setNnfOptions] = useState([]);

  const [membershipStatus, setMembershipStatus] = useState({
    iap: { verified: false, loading: true },
    nnf: { verified: false, loading: true }
  });

  const [whatsappSameAsPhone, setWhatsappSameAsPhone] = useState(false);

  const [registrationAmount, setRegistrationAmount] = useState(0);

  const [accompanyingPersons, setAccompanyingPersons] = useState([]);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const [workshopOnlyMode, setWorkshopOnlyMode] = useState(false);
  const [phoneVerification, setPhoneVerification] = useState({
    phone: '',
    verified: false,
    loading: false,
    error: null,
    registrationData: null
  });

  const workshopOptions = [
    { 
      id: 'neurodevelopmental', 
      name: 'NEURODEVELOPMENTAL WORKSHOP @ IMCH kozhikode',
      forPackages: ['Consultant', 'PG Student', 'Faculty / Chair Person']
    },
    { 
      id: 'neonatal', 
      name: 'NEONATAL PROCEDURAL WORKSHOP @ Astor MIMS hospital',
      forPackages: ['Consultant', 'PG Student', 'Faculty / Chair Person']
    },
    { 
      id: 'ventilation', 
      name: 'VENTILATION WORKSHOP @ BMH Hospital',
      forPackages: ['Consultant', 'PG Student', 'Faculty / Chair Person']
    },
    { 
      id: 'nursing', 
      name: 'NURSING WORKSHOP @ IQRAA Hospital',
      forPackages: ['Nursing Officer']
    }
  ];

  const handleChange = (e) => {
    if (e && e.target) {
      const { name, value } = e.target;
      setFormData(prevState => ({
        ...prevState,
        [name]: value,
        ...(name === 'phone' && whatsappSameAsPhone ? { whatsapp: value } : {})
      }));
    }
  };

  const handlePhoneSubmit = async (e) => {
    e.preventDefault();
    if (formData.phone) {
      try {
        const response = await fetch(`https://zinvos.tessst.com/api/calneo/?phone=${formData.phone}`);
        const data = await response.json();
        
        if (data && data.length > 0) {
          setFormData(prevState => ({
            ...prevState,
            ...data[0]
          }));
        }
        setStep(3);
      } catch (error) {
        console.error('Error fetching user data:', error);
        setStep(3);
      }
    }
  };

  const handlePackageSelect = (e) => {
    handleChange({
      target: {
        name: 'package',
        value: e.target.value
      }
    });
  };

  const handlePackageSubmit = (e) => {
    e.preventDefault();
    if (formData.package) {
      if (formData.package === 'Consultant') {
        setStep(2);
      } else {
        setStep(3);
      }
    }
  };

  const calculateAge = (birthDate) => {
    const today = new Date();
    const birth = new Date(birthDate);
    let age = today.getFullYear() - birth.getFullYear();
    const monthDiff = today.getMonth() - birth.getMonth();
    
    if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birth.getDate())) {
      age--;
    }
    return age;
  };

  const calculateRegistrationAmount = () => {
    let baseAmount = 0;
    
    if (formData.conference) {
      if (formData.package === 'Consultant') {
        const age = formData.dob ? calculateAge(formData.dob) : 0;
        if (age >= 70) {
          baseAmount = 0;
        } else if (formData.member_id && formData.nnf_id) {
          baseAmount = 6000;
        } else if (formData.member_id || formData.nnf_id) {
          baseAmount = 7000;
        } else {
          baseAmount = 10000;
        }
      } else if (formData.package === 'PG Student') {
        baseAmount = 4000;
      } else if (formData.package === 'Nursing Officer') {
        baseAmount = 3000;
      } else if (formData.package === 'Faculty / Chair Person') {
        const age = formData.dob ? calculateAge(formData.dob) : 0;
        if (age >= 70) {
          baseAmount = 0;
        } else {
          baseAmount = 4000;
        }
      }

      baseAmount += (accompanyingPersons.length * 7000);
    }

    if (formData.workshop && formData.package !== 'Nursing Officer') {
      baseAmount += 750;
    }

    return baseAmount;
  };

  const calculateTotalWithGST = (baseAmount) => {
    const gst = baseAmount * 0.18;
    return baseAmount + gst;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (step === 3) {
      const amount = calculateRegistrationAmount();
      setRegistrationAmount(amount);
      setStep(4);
    }
  };

  const handlePaymentSubmit = async () => {
    setIsSubmitting(true);
    try {
      const registrationData = {
        ...formData,
        accompanyingPersons: accompanyingPersons.map(p => ({ name: p.name })),
        amount: calculateRegistrationAmount(),
        total_amount: calculateTotalWithGST(calculateRegistrationAmount())
      };

      const response = await fetch('https://zinvos.tessst.com/api/calneo/register/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(registrationData)
      });

      const data = await response.json();

      if (data.status === 'success') {
        console.log('Registration successful:', data);
        
        if (data.payment_url) {
          window.open(data.payment_url, "_blank");
        }
        else if (calculateRegistrationAmount() === 0) {
          window.location.href = '/registration-success';
        } else {
          throw new Error('Payment URL not received from server');
        }
      } else {
        throw new Error(data.message || 'Registration failed');
      }
    } catch (error) {
      console.error('Registration error:', error);
      alert('Registration failed: ' + error.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  const loadMemberOptions = async (inputValue) => {
    if (!inputValue || inputValue.length < 2) return [];
    
    try {
      const response = await fetch(`https://zinvos.tessst.com/api/calneo/?member_id=${inputValue}`);
      const data = await response.json();
      
      const options = data.map(item => ({
        value: item.member_id,
        label: `${item.name} (${item.member_id})`
      }));

      return options;
    } catch (error) {
      console.error('Error loading members:', error);
      return [];
    }
  };

  const loadNNFOptions = async (inputValue) => {
    if (!inputValue || inputValue.length < 2) return [];

    try {
      const response = await fetch(`https://zinvos.tessst.com/api/calneo/?nnf_id=${inputValue}`);
      const data = await response.json();
      
      const options = data.map(item => ({
        value: item.nnf_id,
        label: `${item.name} (${item.nnf_id})`
      }));

      return options;
    } catch (error) {
      console.error('Error loading NNF options:', error);
      return [];
    }
  };

  const verifyInitialMemberships = async () => {
    setMembershipStatus({
      iap: { verified: false, loading: true },
      nnf: { verified: false, loading: true }
    });

    if (formData.member_id) {
      try {
        const response = await fetch(`https://zinvos.tessst.com/api/calneo/?member_id=${formData.member_id}`);
        const data = await response.json();
        const matchingMember = data.find(m => m.member_id === formData.member_id);
        if (matchingMember) {
          const option = {
            value: matchingMember.member_id,
            label: `${matchingMember.member_id} - ${matchingMember.name}`
          };
          setMemberOptions([option]);
          setFormData(prev => ({
            ...prev,
            member_id: matchingMember.member_id
          }));
          setMembershipStatus(prev => ({
            ...prev,
            iap: { verified: true, loading: false }
          }));
        } else {
          setMembershipStatus(prev => ({
            ...prev,
            iap: { verified: false, loading: false }
          }));
        }
      } catch (error) {
        console.error('Error verifying IAP membership:', error);
        setMembershipStatus(prev => ({
          ...prev,
          iap: { verified: false, loading: false }
        }));
      }
    } else {
      setMembershipStatus(prev => ({
        ...prev,
        iap: { verified: false, loading: false }
      }));
    }
    
    if (formData.nnf_id) {
      try {
        const response = await fetch(`https://zinvos.tessst.com/api/calneo/?nnf_id=${formData.nnf_id}`);
        const data = await response.json();
        const matchingMember = data.find(m => m.nnf_id === formData.nnf_id);
        if (matchingMember) {
          const option = {
            value: matchingMember.nnf_id,
            label: `${matchingMember.nnf_id} - ${matchingMember.name}`
          };
          setNnfOptions([option]);
          setFormData(prev => ({
            ...prev,
            nnf_id: matchingMember.nnf_id
          }));
          setMembershipStatus(prev => ({
            ...prev,
            nnf: { verified: true, loading: false }
          }));
        } else {
          setMembershipStatus(prev => ({
            ...prev,
            nnf: { verified: false, loading: false }
          }));
        }
      } catch (error) {
        console.error('Error verifying NNF membership:', error);
        setMembershipStatus(prev => ({
          ...prev,
          nnf: { verified: false, loading: false }
        }));
      }
    } else {
      setMembershipStatus(prev => ({
        ...prev,
        nnf: { verified: false, loading: false }
      }));
    }
  };

  const handleWhatsappCheckbox = (e) => {
    setWhatsappSameAsPhone(e.target.checked);
    if (e.target.checked) {
      setFormData(prev => ({
        ...prev,
        whatsapp: formData.phone
      }));
    }
  };

  useEffect(() => {
    if (formData.member_id || formData.nnf_id) {
      verifyInitialMemberships();
    }
  }, [formData.member_id, formData.nnf_id]);

  useEffect(() => {
    // Check if we're on the faculty registration page
    if (window.location.pathname === '/faculty-registration') {
      handlePackageSelect({ target: { value: 'Faculty / Chair Person' } });
      setStep(3);
    }
  }, []);

  const handleWorkshopOnlyClick = () => {
    setWorkshopOnlyMode(true);
  };

  const verifyPhoneForWorkshop = async (e) => {
    e.preventDefault();
    
    if (!phoneVerification.phone) {
      setPhoneVerification(prev => ({
        ...prev,
        error: "Please enter your phone number"
      }));
      return;
    }
    
    setPhoneVerification(prev => ({
      ...prev,
      loading: true,
      error: null
    }));
    
    try {
      const response = await fetch(`https://zinvos.tessst.com/api/registration-detail/${phoneVerification.phone}/`);
      const data = await response.json();
      
      if (data.status) {
        // Registration found, populate form with existing data
        setPhoneVerification(prev => ({
          ...prev,
          verified: true,
          loading: false,
          registrationData: data.data
        }));
        
        // Make sure we have a valid package
        const userPackage = data.data.package || 'Consultant'; // Default to Consultant if no package
        
        // Pre-fill form with existing registration data
        setFormData(prevState => ({
          ...prevState,
          name: data.data.name,
          email: data.data.email,
          phone: data.data.phone,
          whatsapp: data.data.whatsapp,
          address: data.data.address,
          city: data.data.city,
          state: data.data.state,
          country: data.data.country,
          pincode: data.data.pincode,
          package: userPackage,
          member_id: data.data.member_id,
          nnf_id: data.data.nnf_id,
          dob: data.data.dob,
          role: data.data.role,
          conference: false, // Since this is workshop-only
          workshop: true,    // Explicitly set workshop to true
          workshopType: ''
        }));
        
        // Move to workshop selection step
        setStep(3);
      } else {
        throw new Error(data.message || "Registration not found");
      }
    } catch (error) {
      setPhoneVerification(prev => ({
        ...prev,
        loading: false,
        error: "No completed conference registration found with this phone number. Please register for the conference first."
      }));
    }
  };

  useEffect(() => {
    if (workshopOnlyMode) {
      console.log("Workshop only mode:", workshopOnlyMode);
      console.log("Workshop flag:", formData.workshop);
      console.log("Package:", formData.package);
      console.log("Workshop options:", workshopOptions.filter(option => option.forPackages.includes(formData.package)));
    }
  }, [workshopOnlyMode, formData.workshop, formData.package]);

  return (
    <div className="registration-container">
      <h2 className="section-title">
        {workshopOnlyMode ? "Workshop Registration" : "Conference Registration"}
        {step > 1 && formData.package && !workshopOnlyMode && (
          <span className="selected-package"> - {formData.package}</span>
        )}
      </h2>
      
      {workshopOnlyMode && !phoneVerification.verified ? (
        <div className="registration-form">
          <h3 className="section-subtitle">Verify Your Conference Registration</h3>
          <p className="verification-info">Please enter the phone number you used for conference registration.</p>
          
          <form onSubmit={verifyPhoneForWorkshop}>
            <div className="form-group">
              <label htmlFor="verification-phone">Phone Number *</label>
              <input
                type="tel"
                id="verification-phone"
                value={phoneVerification.phone}
                onChange={(e) => setPhoneVerification(prev => ({...prev, phone: e.target.value}))}
                placeholder="Enter phone number used for conference registration"
                required
              />
            </div>
            
            {phoneVerification.error && (
              <div className="error-message">{phoneVerification.error}</div>
            )}
            
            <div className="button-group">
              <button 
                type="button" 
                className="back-button"
                onClick={() => {
                  setWorkshopOnlyMode(false);
                  setPhoneVerification({
                    phone: '',
                    verified: false,
                    loading: false,
                    error: null,
                    registrationData: null
                  });
                }}
              >
                Back
              </button>
              <button 
                type="submit" 
                className="submit-button"
                disabled={phoneVerification.loading}
              >
                {phoneVerification.loading ? "Verifying..." : "Verify & Continue"}
              </button>
            </div>
          </form>
        </div>
      ) : step === 1 && !workshopOnlyMode ? (
        <div className="registration-form">
          <h3 className="section-subtitle">Register As</h3>
          <div className="package-buttons">
            {packages.map((pkg, index) => (
              <button
                key={index}
                type="button"
                className={`package-button ${formData.package === pkg.label ? 'selected' : ''}`}
                onClick={() => {
                  handlePackageSelect({ target: { value: pkg.label } });
                  if (pkg.label === 'Consultant') {
                    setStep(2);
                  } else {
                    setStep(3);
                  }
                }}
              >
                <span className="package-label">{pkg.label}</span>
              </button>
            ))}
          </div>
          
          <div className="workshop-only-section">
            <p className="workshop-only-text">Already registered for the conference? Add a workshop to your experience.</p>
            <button 
              type="button" 
              className="workshop-only-button"
              onClick={handleWorkshopOnlyClick}
            >
              Register for Workshop Only
            </button>
          </div>
        </div>
      ) : step === 2 && formData.package === 'Consultant' && !workshopOnlyMode ? (
        <form onSubmit={handlePhoneSubmit} className="registration-form">
          <h3 className="section-subtitle">Consultant Registration</h3>
          <div className="form-group">
            <label htmlFor="phone">Phone Number Registered with IAP / NNF If Registered*</label>
            <input
              type="tel"
              id="phone"
              name="phone"
              value={formData.phone}
              onChange={handleChange}
              placeholder="Enter phone number..."
              required
            />
          </div>
          <button type="submit" className="submit-button">
            Next
          </button>
        </form>
      ) : step === 3 ? (
        <form onSubmit={handleSubmit} className="registration-form">
          <h3 className="section-subtitle">
            {formData.package} Registration Details
          </h3>
          <div className="form-group">
            <label htmlFor="name">Full Name *</label>
            <input
              type="text"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
              disabled={workshopOnlyMode}
            />
          </div>

          {formData.package === 'Faculty / Chair Person' && (
            <>
              <div className="form-group">
                <label>Role *</label>
                <div className="role-radio-group">
                  <div className="role-radio-option">
                    <input
                      type="radio"
                      id="faculty"
                      name="role"
                      value="Faculty"
                      checked={formData.role === 'Faculty'}
                      onChange={(e) => handleChange({
                        target: {
                          name: 'role',
                          value: e.target.value
                        }
                      })}
                      required
                      disabled={workshopOnlyMode}
                    />
                    <label htmlFor="faculty" className="role-label">
                    <span className="role-icon"> </span>
                      <span className="role-text">Faculty</span>
                    </label>
                  </div>
                  <div className="role-radio-option">
                    <input
                      type="radio"
                      id="chairperson"
                      name="role"
                      value="Chair Person"
                      checked={formData.role === 'Chair Person'}
                      onChange={(e) => handleChange({
                        target: {
                          name: 'role',
                          value: e.target.value
                        }
                      })}
                      required
                      disabled={workshopOnlyMode}
                    />
                    <label htmlFor="chairperson" className="role-label">
                      <span className="role-icon"> </span>
                      <span className="role-text">Chair Person</span>
                    </label>
                  </div>
                </div>
              </div>
              <div className="form-group">
                <label htmlFor="dob">Date of Birth *</label>
                <input
                  type="date"
                  id="dob"
                  name="dob"
                  value={formData.dob}
                  onChange={handleChange}
                  required
                  max={new Date().toISOString().split('T')[0]}
                  disabled={workshopOnlyMode}
                />
              </div>
            </>
          )}

          {formData.package === 'Consultant' && (
            <>
              <div className="form-group">
                <label htmlFor="member_id">IAP Membership Number</label>
                {formData.member_id && membershipStatus.iap.verified ? (
                  <input
                    type="text"
                    id="member_id"
                    name="member_id"
                    value={formData.member_id}
                    disabled
                  />
                ) : (
                  <CreatableAsyncSelect
                    id="member_id"
                    name="member_id"
                    value={formData.member_id ? { value: formData.member_id, label: formData.member_id } : null}
                    loadOptions={loadMemberOptions}
                    onChange={(selected) => {
                      handleChange({
                        target: {
                          name: 'member_id',
                          value: selected ? selected.value : ''
                        }
                      });
                      if (selected && !selected.__isNew__) {
                        verifyInitialMemberships();
                      }
                    }}
                    onCreateOption={(inputValue) => {
                      handleChange({
                        target: {
                          name: 'member_id',
                          value: inputValue
                        }
                      });
                      setMembershipStatus(prev => ({
                        ...prev,
                        iap: { verified: false, loading: false }
                      }));
                    }}
                    isClearable
                    placeholder="Type to search or create new member ID..."
                    noOptionsMessage={({ inputValue }) => 
                      inputValue.length < 2 ? "Type at least 2 characters..." : "No results found"
                    }
                    formatCreateLabel={(inputValue) => `Create new ID: "${inputValue}"`}
                    isDisabled={workshopOnlyMode}
                  />
                )}
              </div>
              <div className="form-group">
                <label htmlFor="nnf_id">NNF Membership Number</label>
                {formData.nnf_id && membershipStatus.nnf.verified ? (
                  <input
                    type="text"
                    id="nnf_id"
                    name="nnf_id"
                    value={formData.nnf_id}
                    disabled
                  />
                ) : (
                  <CreatableAsyncSelect
                    id="nnf_id"
                    name="nnf_id"
                    value={formData.nnf_id ? { value: formData.nnf_id, label: formData.nnf_id } : null}
                    loadOptions={loadNNFOptions}
                    onChange={(selected) => {
                      handleChange({
                        target: {
                          name: 'nnf_id',
                          value: selected ? selected.value : ''
                        }
                      });
                      if (selected && !selected.__isNew__) {
                        verifyInitialMemberships();
                      }
                    }}
                    onCreateOption={(inputValue) => {
                      handleChange({
                        target: {
                          name: 'nnf_id',
                          value: inputValue
                        }
                      });
                      setMembershipStatus(prev => ({
                        ...prev,
                        nnf: { verified: false, loading: false }
                      }));
                    }}
                    isClearable
                    placeholder="Type to search or create new NNF ID..."
                    noOptionsMessage={({ inputValue }) => 
                      inputValue.length < 2 ? "Type at least 2 characters..." : "No results found"
                    }
                    formatCreateLabel={(inputValue) => `Create new ID: "${inputValue}"`}
                    isDisabled={workshopOnlyMode}
                  />
                )}
              </div>
              <div className="form-group">
                <label htmlFor="dob">Date of Birth *</label>
                <input
                  type="date"
                  id="dob"
                  name="dob"
                  value={formData.dob}
                  onChange={handleChange}
                  required
                  max={new Date().toISOString().split('T')[0]}
                  disabled={workshopOnlyMode}
                />
              </div>
            </>
          )}

          <div className="form-group">
            <label htmlFor="address">Address *</label>
            <textarea
              id="address"
              name="address"
              value={formData.address}
              onChange={handleChange}
              required
              disabled={workshopOnlyMode}
            />
          </div>

          <div className="form-row">
            <div className="form-group">
              <label htmlFor="city">City *</label>
              <input
                type="text"
                id="city"
                name="city"
                value={formData.city}
                onChange={handleChange}
                required
                disabled={workshopOnlyMode}
              />
            </div>

            <div className="form-group">
              <label htmlFor="state">State *</label>
              <input
                type="text"
                id="state"
                name="state"
                value={formData.state}
                onChange={handleChange}
                required
                disabled={workshopOnlyMode}
              />
            </div>
          </div>

          <div className="form-row">
            <div className="form-group">
              <label htmlFor="country">Country *</label>
              <input
                type="text"
                id="country"
                name="country"
                value={formData.country}
                onChange={handleChange}
                required
                disabled={workshopOnlyMode}
              />
            </div>

            <div className="form-group">
              <label htmlFor="pincode">Pincode *</label>
              <input
                type="text"
                id="pincode"
                name="pincode"
                value={formData.pincode}
                onChange={handleChange}
                required
                disabled={workshopOnlyMode}
              />
            </div>
          </div>

          <div className="form-row">
            <div className="form-group">
              <label htmlFor="email">Email *</label>
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
                disabled={workshopOnlyMode}
              />
            </div>

            <div className="form-group">
              <label htmlFor="phone">Phone *</label>
              <input
                type="tel"
                id="phone"
                name="phone"
                value={formData.phone}
                onChange={handleChange}
                required
                disabled={workshopOnlyMode}
              />
            </div>
          </div>

          <div className="form-group">
            <label htmlFor="whatsapp">WhatsApp Number</label>
            <input
              type="tel"
              id="whatsapp"
              name="whatsapp"
              value={formData.whatsapp}
              onChange={handleChange}
              disabled={whatsappSameAsPhone || workshopOnlyMode}
            />
            <div className="checkbox-group">
              <input
                type="checkbox"
                id="whatsappSameAsPhone"
                checked={whatsappSameAsPhone}
                onChange={handleWhatsappCheckbox}
                disabled={workshopOnlyMode}
              />
              <label htmlFor="whatsappSameAsPhone">Same as phone number</label>
            </div>
          </div>

          {!workshopOnlyMode && (
            <div className="form-group">
              <div className="checkbox-group">
                <input
                  type="checkbox"
                  id="conference"
                  name="conference"
                  checked={formData.conference}
                  onChange={(e) => handleChange({
                    target: {
                      name: 'conference',
                      value: e.target.checked
                    }
                  })}
                  disabled={workshopOnlyMode}
                />
                <label htmlFor="conference">
                  {formData.package === 'Nursing Officer' 
                    ? 'Conference Registration [26.04.25 & 27.04.25] [Paid- 3000/-]' 
                    : 'Register for Conference'}
                </label>
              </div>

              {formData.package === 'Nursing Officer' && (
                <div className="checkbox-group">
                  <input
                    type="checkbox"
                    id="registerForCME"
                    name="registerForCME"
                    checked={formData.registerForCME}
                    onChange={(e) => handleChange({
                      target: {
                        name: 'registerForCME',
                        value: e.target.checked
                      }
                    })}
                    disabled={workshopOnlyMode}
                  />
                  <label htmlFor="registerForCME">
                    Nurses CME [27.04.25] [Free]
                  </label>
                </div>
              )}
              
              {formData.package !== 'Faculty / Chair Person' && (
                <div className="checkbox-group">
                  <input
                    type="checkbox"
                    id="workshop"
                    name="workshop"
                    checked={formData.workshop}
                    onChange={(e) => handleChange({
                      target: {
                        name: 'workshop',
                        value: e.target.checked
                      }
                    })}
                    disabled={workshopOnlyMode}
                  />
                  <label htmlFor="workshop">
                    {formData.package === 'Nursing Officer' 
                      ? 'Nurses Workshop [25.04.25] [Free]' 
                      : 'Register for Workshop (₹750)'}
                  </label>
                </div>
              )}
            </div>
          )}

          {workshopOnlyMode && (
            <div className="workshop-only-info">
              <p>You are registering for a workshop only. Your conference registration details have been pre-filled.</p>
            </div>
          )}

          {(formData.workshop || workshopOnlyMode) && (
            <div className="form-group workshop-selection">
              <label>Select Workshop *</label>
              <div className="workshop-options">
                {workshopOptions
                  .filter(option => option.forPackages.includes(formData.package))
                  .map((option) => (
                    <div key={option.id} className="workshop-option">
                      <input
                        type="radio"
                        id={option.id}
                        name="workshopType"
                        value={option.name}
                        checked={formData.workshopType === option.name}
                        onChange={(e) => handleChange({
                          target: {
                            name: 'workshopType',
                            value: e.target.value
                          }
                        })}
                        required={formData.workshop || workshopOnlyMode}
                      />
                      <label htmlFor={option.id}>{option.name}</label>
                    </div>
                  ))}
              </div>
            </div>
          )}

          <button 
            type="submit" 
            className={`submit-button ${!formData.conference && !formData.workshop && !formData.registerForCME ? 'disabled-button' : ''}`}
            disabled={!formData.conference && !formData.workshop && !formData.registerForCME}
            onClick={(e) => {
              if (!formData.conference && !formData.workshop && !formData.registerForCME) {
                e.preventDefault();
                alert("Please select at least one option: Conference or Workshop or Register for CME");
                return;
              }
              // Continue with form submission
            }}
          >
            Complete Registration
          </button>
        </form>
      ) : (
        <div className="confirmation-screen">
          <h3 className="section-subtitle">
            {formData.package} Registration Summary
          </h3>
          <div className="summary-details">
            <div className="summary-row">
              <span>Name:</span>
              <span>{formData.name}</span>
            </div>
            <div className="summary-row">
              <span>Package:</span>
              <span>{formData.package}</span>
            </div>
            {formData.package === 'Faculty / Chair Person' && (
              <div className="summary-row">
                <span>Role:</span>
                <span>{formData.role}</span>
              </div>
            )}
            {formData.package === 'Consultant' && (
              <>
                <div className="summary-row">
                  <span>IAP Member:</span>
                  <span>{formData.member_id ? 'Yes' : 'No'}</span>
                </div>
                <div className="summary-row">
                  <span>NNF Member:</span>
                  <span>{formData.nnf_id ? 'Yes' : 'No'}</span>
                </div>
              </>
            )}
            <div className="summary-row">
              <span>Email:</span>
              <span>{formData.email}</span>
            </div>
            <div className="summary-row">
              <span>Phone:</span>
              <span>{formData.phone}</span>
            </div>
            <div className="summary-row">
              <span>Workshop:</span>
              <span>{formData.workshop ? 'Yes' : 'No'}</span>
            </div>
            {formData.workshop && formData.workshopType && (
              <div className="summary-row">
                <span>Workshop Type:</span>
                <span>{formData.workshopType}</span>
              </div>
            )}
            {formData.package === 'Nursing Officer' && (
              <div className="summary-row">
                <span>Register for CME:</span>
                <span>{formData.registerForCME ? 'Yes' : 'No'}</span>
              </div>
            )}
            <div className="summary-row">
              <span>Conference:</span>
              <span>{formData.conference ? 'Yes' : 'No'}</span>
            </div>
            
            {!workshopOnlyMode && (
              <div className="accompanying-section">
                <h4 className="accompanying-title">Accompanying Persons</h4>
                <div className="accompanying-list">
                  {accompanyingPersons.map((person, index) => (
                    <div key={index} className="accompanying-item">
                      <div className="accompanying-input-group">
                        <span className="accompanying-number">{index + 1}</span>
                        <input
                          type="text"
                          value={person.name}
                          onChange={(e) => {
                            const newPersons = [...accompanyingPersons];
                            newPersons[index].name = e.target.value;
                            setAccompanyingPersons(newPersons);
                          }}
                          placeholder="Enter full name"
                          className="accompanying-input"
                          required
                        />
                        <button
                          type="button"
                          onClick={() => {
                            setAccompanyingPersons(accompanyingPersons.filter((_, i) => i !== index));
                          }}
                          className="accompanying-remove-btn"
                          aria-label="Remove accompanying person"
                        >
                          ×
                        </button>
                      </div>
                    </div>
                  ))}
                  <button
                    type="button"
                    onClick={() => {
                      setAccompanyingPersons([...accompanyingPersons, { name: '' }]);
                    }}
                    className="accompanying-add-btn"
                  >
                    <span className="accompanying-add-icon">+</span>
                    Add Accompanying Person
                    <span className="accompanying-price">(+₹7000)</span>
                  </button>
                </div>
              </div>
            )}

            <div className="amount-breakdown">
              {formData.conference && (
                <div className="breakdown-row">
                  <span>Base Registration:</span>
                  <span className="breakdown-amount">
                    ₹{calculateRegistrationAmount() - (accompanyingPersons.length * 7000) - (formData.workshop && formData.package !== 'Nursing Officer' ? 750 : 0)}
                  </span>
                </div>
              )}
              {formData.workshop && formData.package !== 'Nursing Officer' && (
                <div className="breakdown-row">
                  <span>Workshop Fee:</span>
                  <span className="breakdown-amount">₹750</span>
                </div>
              )}
              {formData.conference && accompanyingPersons.length > 0 && (
                <div className="breakdown-row">
                  <span>Accompanying Persons ({accompanyingPersons.length}):</span>
                  <span className="breakdown-amount">₹{accompanyingPersons.length * 7000}</span>
                </div>
              )}
              <div className="breakdown-row">
                <span>Subtotal:</span>
                <span className="breakdown-amount">₹{calculateRegistrationAmount()}</span>
              </div>
              <div className="breakdown-row">
                <span>GST (18%):</span>
                <span className="breakdown-amount">₹{(calculateRegistrationAmount() * 0.18).toFixed(2)}</span>
              </div>
            </div>

            <div className="summary-row total-amount">
              <span>Total Amount (inc. GST):</span>
              <span>₹{calculateTotalWithGST(calculateRegistrationAmount()).toFixed(2)}</span>
            </div>
          </div>
          
          <div className="button-group">
            <button 
              type="button" 
              className="back-button"
              onClick={() => setStep(3)}
            >
              <span>Edit Details</span>
            </button>
            <button 
              type="button" 
              className="payment-button"
              onClick={handlePaymentSubmit}
              disabled={isSubmitting || (!formData.conference && !formData.workshop && !formData.registerForCME)}
            >
              {isSubmitting ? (
                <div className="loading-spinner">
                  <div className="spinner"></div>
                  <span>Processing...</span>
                </div>
              ) : (
                <>
                  <span>{calculateRegistrationAmount() === 0 ? 'Register' : 'Pay Now'}</span>
                  <svg 
                    xmlns="http://www.w3.org/2000/svg" 
                    fill="none" 
                    viewBox="0 0 24 24" 
                    stroke="currentColor"
                  >
                    <path 
                      strokeLinecap="round" 
                      strokeLinejoin="round" 
                      strokeWidth={2} 
                      d="M17 8l4 4m0 0l-4 4m4-4H3"
                    />
                  </svg>
                </>
              )}
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default Registration; 