// src/App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './components/Header';
import Home from './components/Home';
import Footer from './components/Footer';
import TermsAndConditions from './components/TermsAndConditions';
import Navbar from './components/Navbar';
import PrivacyPolicy from './components/PrivacyPolicy';
import RefundCancellation from './components/RefundCancellation';
import Registration from './components/Registration';
import AboutUs from './components/AboutUs';
import RegistrationSuccess from './components/RegistrationSuccess';
import './App.css';

function App() {
  return (
    <Router>
      <div className="App">
        <Navbar />
        <Routes>
          <Route path="/terms" element={<TermsAndConditions />} />
          <Route path="/" element={
            <>
              <Header />
              <Home />
            </>
          } />
          <Route path="/privacy" element={<PrivacyPolicy />} />
          <Route path="/refund-cancellation" element={<RefundCancellation />} />
          <Route path="/register" element={<Registration />} />
          <Route path="/faculty-registration" element={<Registration />} />
          <Route path="/aboutus" element={<AboutUs />} />
          <Route path="/registration-success" element={<RegistrationSuccess />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;